<template>
  <Dialog v-model:visible="displayRange" :style="{ width: '60vw' }">
    <template #header>
      <div class="p-dialog-custom-header">
        <h3><strong>Моделируемая переменная</strong></h3>
        <div>Разделитель дробной части - точка (<strong>.</strong>)</div>
      </div>
    </template>
    <div class="p-d-flex p-jc-center p-flex-column">
      <div class="p-mb-2">
        <Textarea
          v-model="editedRangeRaw"
          placeholder="Перечислите границы интервалов или оставьте поле пустым"
          rows="5"
          style="width: 100%"
        />
      </div>
    </div>
    <template #footer>
      <div class="p-d-flex p-jc-around">
        <Button
          label="Отменить"
          icon="pi pi-times"
          @click="onIntervalCancel"
          class="p-button-text"
        />
        <Button
          label="Удалить цель"
          icon="pi pi-trash"
          @click="onTargetRemove"
          class="p-button-text p-button-danger"
        />
        <Button
          label="Сохранить"
          icon="pi pi-check"
          @click="onIntervalSave"
          autofocus
        />
      </div>
    </template>
  </Dialog>

  <div class="p-grid">
    <div class="p-col-12">
      <div
        class="card"
        v-if="isLocalLoaded"
        style="height: calc(100vh - 240px)"
      >
        <div class="flex-row-sb">
          <div class="flex-col-sb" style="width: 100%; margin-right: 5%">
            <div class="flex-row">
              <div class="p-float-label">
                <InputMathNumber
                  id="fisherCriterion"
                  mode="decimal"
                  :min="0"
                  :max="0.1"
                  v-model="learning_parameters.max_fisher"
                  v-tooltip.bottom="'Введите Критерий фишера'"
                />
                <label for="fisherCriterion">Критерий&nbsp;Фишера</label>
              </div>
              <div class="p-float-label" style="margin-left: 4rem">
                <InputMathNumber
                  id="inProbabilityInput"
                  mode="decimal"
                  :min="0.5"
                  :max="1"
                  v-model="learning_parameters.min_probability"
                  v-tooltip.bottom="'Введите минимальную вероятность'"
                />
                <label for="inProbabilityInput">Минимальная&nbsp;вероятность</label>
              </div>
            </div>
            <br /><br />
            <div style="display: block; box-sizing: content-box; width: 100%">
              <div class="p-float-label">
                <MultiSelect
                  id="factorsSelector"
                  :modelValue="getSelectedTargetList"
                  :options="getPossibleTargetList"
                  @change="onMultiSelectChange"
                  display="chip"
                  style="width: 35vw"
                >
                  <template #option="opt">
                    <div style="max-width: 50vw">{{ opt.option }}</div>
                  </template>
                </MultiSelect>
                <label for="factorsSelector">Моделируемые переменные</label>
              </div>
            </div>
            <br /><br />
            <Button
              style="margin-right: 0; width: fit-content"
              v-if="isLocalLoaded"
              :disabled="canSendData == false"
              label="Отправить данные"
              @click="onSendClick"
            />
          </div>
          <div>
            <div class="p-float-label">
              <Textarea
                id="dataDescription"
                :model-value="description"
                @input="description = $event.target.value"
                :auto-resize="true"
                rows="8"
                cols="45"
              />
              <label for="dataDescription">Описание&nbsp;обучения</label>
            </div>
          </div>
        </div>
        <br /><br />
        <div :style="{height: `calc(100vh - ${noTableHigh}px)`}">
          <DataTable
              :value="rawAnalysisData.sheet"
              :scrollable="true"
              scrollDirection="both"
              scrollHeight="flex"
          >
            <Column
                v-for="(header, idx) in rawAnalysisData.headers"
                :style="{ width: getColumnWidth(idx) + 'px' }"
                :field="header"
                :header="header"
                :key="header"
                :frozen="idx === 0"
            >
              <template #body="col">
                <div class="row-header" v-if="idx === 0">
                  <div>
                    <strong
                        :class="
                      isIncludedTarget(col.data[col.column.key])
                        ? 'criterion-selected'
                        : ''
                    "
                    >{{ col.data[col.column.key] }}</strong
                    >
                  </div>
                  <div>
                    <Button
                        v-if="col.data[col.column.key] && false"
                        icon="pi pi-sliders-h"
                        class="p-button-text p-button"
                        @click="onIntervalClick(col.data[col.column.key])"
                        :badge="getIntervalLength(col.data[col.column.key])"
                        :badgeClass="
                      isIncludedTarget(col.data[col.column.key])
                        ? 'p-badge-success'
                        : 'p-badge-info'
                    "
                    />
                  </div>
                </div>
                <div v-if="idx !== 0">
                  {{ col.data[col.column.key] }}
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="card" v-else>
        <p>Файл с данными не загружен</p>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import { inject } from 'vue'
import { routes } from '@/router'
import MultiSelect from 'primevue/multiselect'
import InputMathNumber from "@/components/InputMathNumber";

const MIN_FISHER = 0
const MAX_FISHER = 0.1
const MIN_PROBABILITY = 0.5
const MAX_PROBABILITY = 1

export default {
  components: { DataTable, Column, Button, Textarea, MultiSelect, InputMathNumber },
  data() {
    return {
      learning_parameters: {
        max_fisher: 0.05,
        min_probability: 0.75,
        min_exec_count: 1,
      },
      displayRange: false,
      editedRow: null, // id (название) редактируемого элемента
      editedRangeRaw: '', // строка, введённая пользователем в поле ввода интервалов
      description: '',
    }
  },
  setup() {
    const rawAnalysisData = inject('rawAnalysisData')
    const setTarget = inject('setTarget')
    const removeTarget = inject('removeTarget')
    const addNewTask = inject('addNewTask')
    const hideSpinner = inject('hideSpinner')
    const showSpinner = inject('showSpinner')

    return {
      rawAnalysisData,
      setTarget,
      removeTarget,
      addNewTask,
      hideSpinner,
      showSpinner,
    }
  },
  mounted() {
    if (this.isLocalLoaded && !this.getSelectedTargetList?.length) {
      this.setDefaultTarget()
    }
  },
  computed: {
    isLocalLoaded() {
      return this.rawAnalysisData.sheet.length > 0
    },
    windowHigh() {
      return  Math.max(document.documentElement.clientHeight || 0,
          document.body.clientHeight || 0,
          window.innerHeight || 0)
    },
    noTableHigh() {
      return 50 // page header
          + 52  // page footer
          + 52  // between card and footer
          + 162 // settings
          + 25  // between settings and table
          + 100 // to be
    },
    getPossibleTargetList() {
      return this.rawAnalysisData.sheet
        .map((el) => el[Object.keys(el)[0]])
        .filter((el) => el)
    },
    getSelectedTargetList() {
      return Object.keys(this.rawAnalysisData.target)
    },
    canSendData() {
      return this.getSelectedTargetList?.length &&
          this.learning_parameters.max_fisher >= MIN_FISHER &&
          this.learning_parameters.max_fisher <= MAX_FISHER &&
          this.learning_parameters.min_probability >= MIN_PROBABILITY &&
          this.learning_parameters.min_probability <= MAX_PROBABILITY
    },
  },
  watch: {
    isLocalLoaded() {
      // при загрузке данных задаём дефолтную цель
      if (this.isLocalLoaded) {
        this.setDefaultTarget()
      }
    },
  },
  methods: {
    getColumnWidth(number) {
      switch (number) {
        case 0: return 300
        case 1: return 100
        case 2: return 130
        case 3: return 70
        default: return 200
      }
    },
    isIncluded(id) {
      return this.rawAnalysisData.includeAttributes.includes(id)
    },
    isTarget(id) {
      return this.rawAnalysisData.targetAttributes.includes(id)
    },
    isDiscret(id) {
      return this.rawAnalysisData.discretteAttributes.includes(id)
    },
    setDefaultTarget() {
      const defaultTarget = this.getPossibleTargetList[0]
      this.setTarget(defaultTarget, [])
      console.log('setted default target: ' + defaultTarget)
    },
    onMultiSelectChange(event) {
      const newVals = event.value
      const oldVals = this.getSelectedTargetList

      const added = newVals.filter((v) => !oldVals.includes(v))
      const removed = oldVals.filter((v) => !newVals.includes(v))

      if (added) {
        added.forEach((el) => this.setTarget(el, []))
      }
      if (removed) {
        removed.forEach((el) => this.removeTarget(el))
      }
    },
    onIntervalClick(id) {
      if (this.rawAnalysisData.target[id]) {
        this.editedRangeRaw = this.rawAnalysisData.target[id].join(', ')
      } else {
        this.editedRangeRaw = ''
      }

      this.editedRow = id
      this.displayRange = true
    },
    onIntervalCancel() {
      this.displayRange = false
      this.editedRow = null
      this.editedRangeRaw = ''
    },
    getIntervalLength(id) {
      return this.rawAnalysisData.target[id]
        ? this.rawAnalysisData.target[id].length + ''
        : '0'
    },
    isIncludedTarget(id) {
      return this.rawAnalysisData.target[id]?.length >= 0
    },
    onIntervalSave() {
      let intervals
      if (this.editedRangeRaw.trim()) {
        intervals = this.editedRangeRaw
          .split(',')
          .map((value) => parseFloat(value.trim()))
      } else {
        intervals = []
      }
      this.setTarget(this.editedRow, intervals)

      this.onIntervalCancel()
    },
    onTargetRemove() {
      this.removeTarget(this.editedRow)

      this.onIntervalCancel()
    },
    async onSendClick() {
      const data = {
        target: this.rawAnalysisData.target,
        data: this.rawAnalysisData.data,
        table: this.rawAnalysisData.table,
        learning_parameters: this.learning_parameters,
        description: this.description,
      }

      console.log('sent data', data)
      try {
        this.showSpinner()

        const res = await this.$analytycCrud.post('/fitRequest', data)

        console.log('result', res)

        if (!res || !res.data) {
          this.reportError('Неизвестная ошибка', 'Обратитесь в вашу службу поддержки')
          return
        }

        switch (res.data.code) {
          case 0:
            if (res.data.task_ids) {
              Object.keys(res.data.task_ids).forEach((task_id) => {
                if (res.data.task_ids[task_id].code === 0) {
                  const tsk = {
                    id: res.data.task_ids[task_id].id,
                    description: this.description,
                    start_time: new Date(),
                    status: res.data.task_ids[task_id].status,
                  }
                  this.addNewTask(tsk)
                } else {
                  this.reportError(`Что-то не так с моделируемой переменной "${task_id}"`,
                      `Сообщение от сервера: "${res.data.task_ids[task_id].msg}"`)
                }
              })

              await this.$router.push(routes[0].children[2].path)
            } else {
              this.reportError('Данные отправлены с ошибкой', 'Сервер не вернул идентификаторы')
            }
            break
          case 4:
            this.reportError('Данные не отправлены', 'Некорректные обучающие параметры')
            break
          case 5:
            this.reportError('Данные не отправлены', 'Не задана ни одна моделируемая переменная')
            break
          default:
            this.reportError('Неизвестная ошибка',
                `Код ${res.data.code}\nСообщение сервера: ${res.data.msg}`)
        }
      } catch (e) {
        this.reportError('Данные не отправлены', 'Обратитесь в вашу службу поддержки', e)
      } finally {
        this.hideSpinner()
      }
    },
    reportError(summary, detail, e) {
      const err = e ? e : `summary: ${summary}\ndetail: ${detail}`

      this.$toast.add({severity: 'error', summary: summary, detail: detail, life: 5000,})
      console.error('something went wrong', err)
    },
  },
}
</script>

<style lang="scss" scoped>
.button-box {
  display: flex;
  justify-content: center;

  .p-button {
    margin-right: 0;
  }
}

.criterion-selected {
  color: var(--green-600);
}

.p-badge.p-badge-info {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  margin: 0;
}

.row-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.p-button {
  margin-right: 0.5rem;
  padding: 3px 6px 6px 3px;
}

.p-button.p-button-icon-only {
  width: 3rem;
}

.p-dialog-custom-header {
  display: flex;
  flex-direction: column;
}

.p-buttonset {
  .p-button {
    margin-right: 0;
  }
}

.sizes {
  .button {
    margin-bottom: 0.5rem;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-col-sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .p-button {
    margin-bottom: 0.5rem;

    &:not(.p-button-icon-only) {
      display: flex;
      width: 100%;
    }
  }

  .p-buttonset {
    .p-button {
      margin-bottom: 0;
    }
  }
}
</style>
